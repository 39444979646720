import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import {
  MaterialsListRequestParams,
  MaterialsService as ApiMaterialsService,
  MaterialRequest,
  MaterialsChangelogListRequestParams,
} from '@cat-ai-us-fe/api';

@Injectable({
  providedIn: 'root',
})
export class MaterialsService {
  constructor(private readonly service: ApiMaterialsService) {}

  materialsList(requestParameters: MaterialsListRequestParams = {}) {
    return this.service
      .materialsList(requestParameters)
      .pipe(map((material) => material.results));
  }

  getMaterial(id: number) {
    return this.service.materialsRetrieve({
      id,
    });
  }

  getMaterialLogs(params: MaterialsChangelogListRequestParams) {
    return this.service.materialsChangelogList(params);
  }

  createFormMaterial(formId: number, title: string) {
    return this.service.materialsCreate({
      materialRequest: {
        object_id: formId,
        type: MaterialRequest.TypeEnum.Form,
        title,
      },
    });
  }

  downloadFile(materialId: number) {
    return this.service.materialsDownloadRetrieve({
      id: materialId,
    });
  }
}
